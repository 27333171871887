#navmenu_secondary {
  @include media-breakpoint-down(lg) { display: none;}
  float: left;
  padding:10px 211px 0;
  text-align: left;
  li {
    display: inline-block;
    margin: 0 17px 0 0;
    a {
      color: white;
      font-size: 13px;
      font-weight: 500;
      padding-bottom: 18px;
    }
    img {
      width: auto;
      height: 11px;
      vertical-align: baseline;
    }
  }
  ul {
    padding: 8px;
    width: 180px;
    li {
      display: block;
      a {
        color: black;
        font-size: 13px;
        font-weight: normal;
      }
    }
  }
  .name-account:hover > .login-links-menu{
    display: block;
    transition: all .5s;
  }
  .login-links-menu{
    position: absolute;
    left:-26px;
    top:37px;
    width:187px;
    background: #fff;
    z-index: 22;
    border: 1px solid #b7b7b7;
    padding-left: 0;
    padding-right: 0;
    display: none;
    &:before{
      content: '';
      position: absolute;
      top: -13px;
      left: 35%;
      width: 0;
      height: 0;
      border-bottom: solid 13px white;
      border-left: solid 16px transparent;
      border-right: solid 16px transparent;
  }
    li{
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin-right: 0;
      a {
        padding-left: 38px;
        font-size: 1rem;
      }
    }
  }
}

/* TOP LEVEL LINKS */

/* SUB MENU LEVEL LINKS */

#navmenu .navmenu_secondary_top_links {
  color: white;
  float: left;
}
