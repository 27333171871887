.infobar-mobile {
  white-space: nowrap;
  padding-top: 0.3rem;
  height: 2.5rem;
  display: flex;
  float: right;
  width: 70%;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-up(xl) {display: none;}
  @include media-breakpoint-down(lg) {float: none; width:100%;}
  @include media-breakpoint-down(xs) {  height: auto; padding-top:0;}
}

.feature-set-mobile {
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  float: left;
}
.trust-pilot-set-mobile{
  margin-left: auto;
  float: right;
  span{
    @include media-breakpoint-down(md) { font-size: 0.8125rem;}
  }
}
.free-mobile{
  @include media-breakpoint-down(xs) { margin-left: 0;}
}

.text-top-term-mobile {
  font-size: 0.8125rem;
  @include media-breakpoint-down(xs) { font-size: 0.6875rem;}
}

.text-top-quote-mobile {
  font-size: 0.6875rem;
  color: #fff;
  line-height: initial;
  font-weight: 600;
  @include media-breakpoint-down(sm) {text-transform: uppercase;}
}
.guarantee{
  @include media-breakpoint-down(lg) { margin-top:-1px;}
}
.warranty{
  @include media-breakpoint-between(md,lg) { display: flex;}
}

.middle-set-mobile {
  display: flex;
  align-items: center;
  padding: 0 4px 0 4px;
  @include media-breakpoint-down(sm) {text-transform: uppercase;}
  @include media-breakpoint-down(xs) { font-size: 0.6875rem;}
}

.images-trustpilot-mobile-block1 {
  width: 4.5rem;
}

.images-trustpilot-mobile-block2 {
  width: 4rem;
  vertical-align: sub;
  @include media-breakpoint-down(md) {  width: 3.125rem;}
}

#trust-pilot-mobile{
  @include media-breakpoint-down(sm) {  display: none;}
}
