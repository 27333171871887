#mobile-nav-wrapper {
    display:none;
    text-align:left;
    width: 0%;
    height: 0%;
    z-index: 1001;
    position: relative;
    background-image:url(/media/gtech/nav/mobilenav_grey_background.png);
    background-position-x: left;
    background-size: 54%;
    background-repeat: repeat-y;
    background-color: #fff;
    z-index: 10000;
    @media only screen and (max-width: 1280px) {
        position: absolute;
        top: 115px;
        z-index: 100;
    }
    @include media-breakpoint-down(md) {
        position: fixed;
        background-color: rgba(0,0,0,0.75);
        background-image: none;
        top: 0;
        left: 0;
        .container { float: left;}
    }
    .live-chat-icon {
        height: 25px;
    }
}
.fixed-position {
    overflow: hidden;
}
#mobile-nav-wrapper.mobile-menu-show {
    display: block;
    transition: display 0.5s;
    width: 100%;
    height: auto;
    margin-top: 0px;
    overflow-y: scroll;
    z-index: 1000;
    @include media-breakpoint-down(md) {
        height: 100%;
    }
}

#mobile-nav-wrapper {
    .container {
        padding-left: 0;
    }
}

#mobile-menu-hamburger-btn {
    border-radius: 5px;
    font-weight: 400;
    border: 0;
    padding: 0rem;
    color: #5e696e;
    text-decoration: none;
    cursor: pointer;
    display: block;
    float:right;
    font-size: 1.3125rem;
    margin-top: 5px;
    @include media-breakpoint-down(sm) {
        height: 40px;
    }
    @include media-breakpoint-down(xs) {
        margin-top: -5px;
    }

    &:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 38px;
        line-height: inherit;
        color: #5e696e;
        content: '\e609';
        font-family: 'icons-blank-theme';
        vertical-align: middle;
        display: inline-block;
        font-weight: 600;
        overflow: hidden;
        speak: none;
        text-align: center;
        margin-top: -3px;
    }


}

#mobile-menu {
    width: 100%;
    @include media-breakpoint-down(md) {
        width: 90vw;
        background-color: #ebebeb;
    }
    overflow:hidden;
    display:flex;
    text-align:left;
    .explore-all a{
        color: $gtech-green !important;
    }
    #mobile-menu-catalog-links {
        position: relative;
        padding: 1rem 0rem 2rem 1.25rem;
        #mobile-menu-catalog-links-abs {
            position: absolute;
            ul {
                width: 500px;
                padding: 0.75rem 1.25rem 1.25rem 0rem;
                @include media-breakpoint-down(md) {
                    width: 90vw;
                    padding-left: 2rem;
                }
                position: absolute;
                top: 0; left: 0;

                list-style-type:none;
                background-color: #ebebeb;
                li {
                    a {
                        font-family: "canada-type-gibson",sans-serif;
                        text-transform: uppercase;
                        text-decoration: none;
                        font-weight: 400;
                        font-size: 1.25rem;
                        color: #232c35;
                        height: 60px;
                        display: block;
                        background-image: url(https://www.gtech.co.uk/media/gtech/nav/navmobile_item_bottom_border.png);
                        background-repeat: repeat-x;
                        background-position-y: bottom;
                        padding: 1rem 0 0 0;
                        @media screen and (max-width: 480px) {
                            font-size: 1rem;
                        }
                    }
                    .back-btn {
                        a {
                            color: #5e696e;
                        }
                    }
                    .product {
                        a {
                            padding-left: 1.875rem;
                            text-transform: none;
                        }
                    }
                }
                li:last-child { a { background-image: none;}}
            }
        }
    }
    .panel-collapsed { display:none; }
    .panel-show { display:block; }

    #mobile-menu-extra-links {
        padding-top: 25px;
        padding-left: 95px;
        @include media-breakpoint-down(md) {
            background-color: #e0e0e0;
            width: 90vw;
            height: 220px;
            padding: 0px;
        }
        position: relative;
        ul {
            width: 100%;
            li {
                margin: 1.25rem 0rem 1.25rem 0rem;
                a {
                    font-size: 1.375rem;
                    letter-spacing: -.010rem;
                    @include media-breakpoint-down(md) {
                        font-size: 1.125rem;
                    }
                    color: #232c35;
                }
            }
        }
    }
}

#mobile-close
{
    display: none;
    @include media-breakpoint-down(md) {
        display:flex;
    }
    position: absolute;
    z-index: 3;
    right: 7%; /* if changed - you must set mobile-menu width to align the btn correctly */
    top: 51px;
    padding: 10px 14px;
    margin-right: -17px;
    background-color: #f3f3f3;
    font-size: 20px;
    text-align: left;
    border-radius: 50%;
    cursor:pointer;
    i {
        font-size: 1.4rem;
    }
}

.back-top {
    width:45px;
    height:45px;
    position:fixed;
    bottom:18px;
    left:18px;
    display:none;
    opacity:0.8;
    z-index: 100;
    cursor: pointer;
}

#gtech-logo-mobile
{
    float: left;
    width: 100px;
    height: 100px;
    position: absolute;
    z-index: 2002;
    @include media-breakpoint-down(md) {
        z-index: 2;
    }
    top:2.5rem;
    @include media-breakpoint-down(xs) { top:1.9rem; width: 90px; height: 90px;}
    img {
        width: 100px;
        height: 100px;
        @include media-breakpoint-down(xs) { width: 90px; height: 90px;}
    }
}

.desktop-footer-nav-phone {
    display: flex;
    align-items: center;
}



/* OLD M1 STYLES - MOVE ANY WE USE ABOVE THIS LINE - DELETE ANY BELOW WE DONT USE! */


#hamburger_menu_icon {
    font-size: 30px;
    margin-right: 10px;
    color: $gtech-grey;
}

#hamburger_menu_label {
    font-size: 21px;
    color: #5e696d;
}

#nav_mobile_fade_background {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:1000px;
    background-color: black;
    opacity: 0.75;
    z-index: 250;
}

#nav_mobile_title
{
    float: left;
    height: 70px;
    display: flex;
    @include media-breakpoint-up(xl) { display: none !important; width: 0 !important;} /* !important to hide nav if open when browser resized */
    align-items: center;
    color: $gtech-grey;
    font-size: 21px;

    img {
        height: 18px;
        width: auto;
        margin-left: 5px;
    }
    .main-manu-mobile{
        color:$gtech-grey;
        font-size: 24px;
    }
}

#nav_mobile_root_title
{
    display: flex;
    align-items: center;
}

.nav_mobile_cat_title {
    color: #999999;
    font-weight: 600;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    height: 77px;
    clear: both;
    padding: 24px 0 18px 0;
    background: #fff;

    i {
        padding-left: 40px;
        padding-right: 15px;
        color: #636469;
        @include media-breakpoint-down(sm) { padding-left: 20px; vertical-align: text-top;}
    }

    span {
        color: #999999;
        font-weight: normal;
        @include media-breakpoint-down(sm) { font-size: 22px;}
    }
}



#nav_mobile_content {
    margin: 0;
    @include media-breakpoint-up(xl) { display: none !important;} /* !important to hide nav if open when browser resized */
    @include media-breakpoint-up(lg) {border-bottom: 1px solid #b3b5b4;}
    @include media-breakpoint-down(sm) {
        background-color: #f3f3f3;
        position: absolute;
        top: 0;
        left: 0;
        width: 93%;
        z-index: 999999;
    }
    @include media-breakpoint-between(md,lg) {
        background-color: white;
        background-image: url('/media/gtech/nav/mobilenav_grey_background.png');
        background-position-x: left;
        background-size: 52%;
        background-repeat: repeat-y;
    }
}
.navmenu_mobile_product_title{
    @include media-breakpoint-down(sm) {
        font-size: 16px;
        color:#000000;
        font-weight:600;
    }
}

.navmenu_mobile_ebike_title{
    @include media-breakpoint-down(sm) {
        font-size: 30px;
        color:#333333;
        line-height: 1;
        letter-spacing: -0.010rem;
    }
}

#nav_mobile_secondary_links {
    padding-top: 50px;
    padding-left: 7%;
    @include media-breakpoint-only(md) { padding-left: 2%;}
    @include media-breakpoint-down(sm) { padding-top: 22px;  padding-left: 5%;}
    ul {
        li {
            padding: 13px 0 13px 0;
            @include media-breakpoint-down(sm) { padding: 9px 0 9px 0;}
            a {
                padding: 8px 0 8px 0;
                color: #232c35;
                font-size: 22px;
                letter-spacing: -0.010rem;

            }
        }
    }

    @include media-breakpoint-down(sm) {
        background-color: #e0e0e0;
        ul { li { a {color: #232c35; font-size: 18px;} } }
        i {color: white;}
    }
}

.user-icon-nav-mobile{
    width: 18px;
    vertical-align: baseline;
    @include media-breakpoint-down(sm) { width: 14px; margin-right: 1%; }
}

#nav_mobile_root_panel{
    ul{
        @include media-breakpoint-down(sm) {
            padding-left: 0;
        }
    }
}

.nav_mobile_categories {
    padding-top:35px;
    @include media-breakpoint-down(sm) { padding-top: 0; padding-right: 0;}
    ul li {
        width: 100%;
        display: block;
        .subcategory-mobile-nav{
            font-size: 24px;
            color:$gtech-grey;
            @include media-breakpoint-down(sm) { font-size:20px;}
        }
        .subcategory-link-menu{
            @include media-breakpoint-down(md) { font-size:19px;}
        }
        a {
            text-transform: uppercase;
            text-decoration: none;
            font-weight: 500;
            font-size: 24px;
            padding: 12px 0 0 0;
            width: 85%;
            height: 60px;
            display: block;
            color:#232c35;
            background-image: url('/media/gtech/nav/navmobile_item_bottom_border.png');
            background-repeat: repeat-x;
            background-position-y: bottom;
            @include media-breakpoint-down(md) { font-size: 22px; width:100%;}
            @include media-breakpoint-down(sm) { font-size:20px; padding: 16px 0 0 0;}
        }
        .ebike-nav-category-link{
            height: 90px;
            @include media-breakpoint-down(sm) { height: 123px;}
            @include media-breakpoint-down(xs) { height: 95px;}
            img{
                width: 30%;
                margin-right: 50px;
            }
            .hybrid-category{
                color: #5e696e;
                font-size: 1rem;
                line-height: 0.4;
                display: block;
                text-transform: initial;
            }
            .city-category{
                display: block;
            }
        }
        .nav_mobile_categories_image {
            width: 60px;
            height: 70px;
            float: left;
            margin-right: 15px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
        }

        ul {
            padding-left: 0;
            li {
                padding: 0;
                background: none;
                a {
                    font-size: 24px;
                    color:#232c35;
                    padding-left: 30px;
                    @include media-breakpoint-down(md) { font-size: 22px;}
                    @include media-breakpoint-down(sm) { font-size: 20px;}
                }
            }
        }
    }

}

.open-category{
    padding-top:35px;
}

.nav_mobile_categories_no_border {
    background: none !important;
    a {
        background: none !important;
    }
}

#nav_mobile_dash_icon
{
    background-image: url('/media/gtech/header/icon_account_dashboard_white.svg');
    @include media-breakpoint-between(md,lg) { background-image: url('/media/gtech/header/icon_account_dashboard.svg');}
    background-size: 100% auto;
    background-position: center;
    width: 19px;
    height: 19px;
    margin-top: 7px;
    float: left;
    margin-right: 5px;
}

.right-panel-mobile{
    @include media-breakpoint-down(sm) { padding-left: 20px;}

}

#nav_mobile_about_gtech_icon
{
    background-image: url('/media/gtech/header/icon_about_gtech_white.svg');
    @include media-breakpoint-only(md) { background-image: url('/media/gtech/header/icon_about_gtech_black.svg');}
    background-size: 100% auto;
    background-position: center;
    width: 16px;
    height: 16px;
    float: left;
    margin-right: 5px;
}


/* Mobile Nav Promos */
#nav_mobile_floorcare_upright_panel, #nav_mobile_floorcare_stick_panel, #nav_mobile_floorcare_handheld_panel, #nav_mobile_floorcare_sweepers_panel{
    background: url(/media/gtech/nav/promo-floorcare.png) no-repeat;
    background-size: 400px;
    background-position: 107% 100%;
    height: 500px;
    @include media-breakpoint-down(lg) {padding-left: 40px; background: url(/media/gtech/nav/promo-floorcare-tablet.png) no-repeat; margin-right: 0;
        background-position: right;}
    @include media-breakpoint-down(md) {background-size: 33%; background-position: 100% 100%;}
    @include media-breakpoint-down(sm) {height:100%; padding-left: 0;background-size: 31%;}
    @include media-breakpoint-down(xs) {background-size: 44%;}
    .navmenu_mobile_content_right_floorcare{
        padding-top: 55px;
        padding-left: 55px;
        @include media-breakpoint-down(md) {padding-left: 27px;}
        @include media-breakpoint-down(sm) {padding-left: 20px; padding-top: 15px; padding-bottom: 38px;height:220px;}

    }
}

#nav_mobile_garden_lawnmowers_panel, #nav_mobile_garden_grass_trimmers_panel, #nav_mobile_garden_hedge_trimmers_panel, #nav_mobile_garden_utility_panel{
    background: url(/media/gtech/nav/promo_garden.png) no-repeat;
    background-size: contain;
    background-position: 170% 100%;
    height: 500px;
    @include media-breakpoint-down(lg) {padding-left: 40px; margin-right: 0;}
    @include media-breakpoint-down(md) { background: url(/media/gtech/nav/promo-garden-tablet.png) no-repeat; background-position: right 135%;
        background-size: 40%;}
    @include media-breakpoint-down(sm) {height:100%; padding-left: 0; background-size: 33%; background-position: 100% 100%;}
    @include media-breakpoint-down(xs) {background-size: 48%;}
    .navmenu_mobile_content_right_garden{
        padding-top: 55px;
        padding-left: 55px;
        @include media-breakpoint-down(md) {padding-left: 27px;}
        @include media-breakpoint-down(sm) {padding-left: 20px;padding-top: 15px; padding-bottom: 38px; height:220px;}
    }
}

#nav_mobile_floorcare_panel {
    background: url(/media/gtech/nav/promo-floorcare.png) no-repeat;
    background-size: 400px;
    background-position: 107% 100%;
    height: 500px;
    @include media-breakpoint-down(lg) {padding-left: 50px; background: url(/media/gtech/nav/promo-floorcare-tablet.png) no-repeat; margin-right: 0;
        background-position: right;}
    @include media-breakpoint-down(md) {padding-left: 45px; background-size: 33%; background-position: 100% 100%;}
    @include media-breakpoint-down(sm) {height:100%; padding-left: 0; background-size: 31%; background-position: 100% 100%;}
    @include media-breakpoint-down(xs) {background-size: 44%;}
    .navmenu_mobile_content_right_floorcare{
        padding-top: 55px;
        padding-left: 50px;
        @include media-breakpoint-down(md) {padding-left: 24.5px;}
        @include media-breakpoint-down(sm) {padding-left: 20px; padding-top: 15px; padding-bottom: 38px; height: 220px;}

    }
}

#nav_mobile_garden_panel {
    background: url(/media/gtech/nav/promo_garden.png) no-repeat;
    background-size: contain;
    background-position: 170% 100%;
    height: 500px;
    @include media-breakpoint-down(lg) {padding-left: 50px; margin-right: 0;}
    @include media-breakpoint-down(md) {padding-left: 45px; background: url(/media/gtech/nav/promo-garden-tablet.png) no-repeat; background-position: right 135%;
        background-size: 40%;}
    @include media-breakpoint-down(sm) {height:100%; padding-left: 0; background-size: 39%; background-position: 100% 110%;}
    @include media-breakpoint-down(xs){background-size: 48%; background-position: 100% 100%;}
    .navmenu_mobile_content_right_garden{
        padding-top: 55px;
        padding-left: 50px;
        @include media-breakpoint-down(md) {padding-left: 24.5px;}
        @include media-breakpoint-down(sm) {padding-left: 20px; padding-top: 15px; padding-bottom: 38px; height: 220px;}
    }
}

#nav_mobile_ebikes_panel{
    background: url(/media/gtech/nav/promo_ebikes.png) no-repeat;
    background-size: 43%;
    background-position: 122% 100%;
    height: 500px;
    @include media-breakpoint-down(lg) {padding-left: 50px; margin-right: 0; background-size: 47%; background-position: 118% 100%;}
    @include media-breakpoint-down(md) {padding-left: 45px; background-size: 63%; background-position: 152% 80%;}
    @include media-breakpoint-down(sm) {height:100%; padding-left: 0; background-size: 52%; background-position: 111% 109%;}
    @include media-breakpoint-down(xs){ background-size: 65%; background-position: 151% 100%;}
    .navmenu_mobile_content_right_ebikes{
        padding-top: 95px;
        padding-left: 50px;
        @include media-breakpoint-down(md) {padding-left: 24.5px;}
        @include media-breakpoint-down(sm) {padding-left: 20px; padding-top: 15px; padding-bottom: 38px; height: 220px;}
    }
}

#nav_mobile_massage_panel{
    background: url(/media/gtech/nav/massage-bed-nav.png) no-repeat;
    background-size: 50%;
    background-position: 110% 56%;
    height: 368px;
    @include media-breakpoint-down(lg) {padding-left: 50px; margin-right: 0;}
    @include media-breakpoint-down(sm) {height:100%; padding-left: 0; background-size: 51%;background-position: 100% 88%;}
    @include media-breakpoint-down(xs){  background-size: 100%; background-position: 100% 90%;}
    .navmenu_mobile_content_right_massage{
        padding-top: 55px;
        padding-left: 50px;
        @include media-breakpoint-down(md) {padding-left: 24.5px;}
        @include media-breakpoint-down(sm) {padding-left: 20px; padding-top: 15px; padding-bottom: 38px; height: 220px;}
    }
}

#nav_mobile_bundles_panel{
    background: url(/media/gtech/nav/promo_bundles.png) no-repeat;
    background-size: 50%;
    background-position: 110% 0;
    height: 368px;
    @include media-breakpoint-down(lg) {padding-left: 50px; margin-right: 0;}
    @include media-breakpoint-down(md) {padding-left: 45px;background: url(/media/gtech/nav/promo-bundles-tablet.png) no-repeat; background-size: 49%; background-position: 113% 37%;}
    @include media-breakpoint-down(sm) {height:100%; padding-left: 0; background-size: 51%;background-position: 100% 88%;}
    @include media-breakpoint-down(xs){  background-size: 70%; background-position: 100% 88%;}
    .navmenu_mobile_content_right_bundles{
        padding-top: 55px;
        padding-left: 50px;
        @include media-breakpoint-down(md) {padding-left: 24.5px;}
        @include media-breakpoint-down(sm) {padding-left: 20px; padding-top: 15px; padding-bottom: 38px; height: 220px;}
    }
}

#nav_mobile_mlogout_panel{
    height: 368px;
    @include media-breakpoint-down(lg) {padding-left: 50px; margin-right: 0;}
    @include media-breakpoint-down(md) {padding-left: 45px;}
    @include media-breakpoint-down(sm) {height:100%; padding-left: 0; background-size: 80%; background-position: 100% 88%;}
    .navmenu_mobile_content_right_bundles{
        padding-top: 55px;
        padding-left: 50px;
        @include media-breakpoint-down(md) {padding-left: 24.5px;}
        @include media-breakpoint-down(sm) {padding-left: 20px; padding-top: 15px; padding-bottom: 38px; height: 220px;}
    }
}


#nav_mobile_floorcare_upright_panel, #nav_mobile_floorcare_stick_panel, #nav_mobile_floorcare_handheld_panel, #nav_mobile_floorcare_sweepers_panel, #nav_mobile_garden_lawncare_panel, #nav_mobile_garden_cutting_panel, #nav_mobile_garden_utility_panel{
    .open-category{
        padding-left: 25px;
        @include media-breakpoint-down(sm) {padding-left: 20px;}
    }
}
#navmenu_mobile_footer_wrapper {
    background-color: #636469;
    @include media-breakpoint-down(md) {width: 90vw !important; float:left;}
    .navmenu_mobile_footer {
        position: relative;
        width: 100%;
        height: 70px;
        margin: 0;
        display: flex;
        align-items: center;

        .mobie-nav-footer-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }
        }

        .left-nav-footer-panel {
            @include media-breakpoint-down(sm) {
                margin-top: 25px;
                width: 100%;
            }

            .register-nav-footer-mobile {
                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }

        .right-nav-footer-panel {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 0.5rem;
            width: 50%;
            @include media-breakpoint-down(md) {
                margin-top: 0;
                width: 60%;
            }
            @include media-breakpoint-down(sm) {
                flex-direction: column;
                width: 100%;
                margin-top: 30px;
                align-items: baseline;
            }

            .offline-chat-mobile {
                text-align: right;
                @include media-breakpoint-down(md) {
                    width: 80%;
                }
                @include media-breakpoint-down(sm) {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    margin-bottom: 1%;
                }
            }

            .phone-number-mobile-container {
                display: inline;
                margin-right: 1rem;
                width: 60%;
                @include media-breakpoint-down(md) {
                    text-align: right;
                    margin-right: 0;
                    width: 90%;
                }
                @include media-breakpoint-down(sm) {
                    text-align: left;
                    display: flex;
                    align-items: center;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            height: 170px;
            align-items: flex-start;
        }

        a {
            @include media-breakpoint-down(md) {
                font-size: 0.8rem;
                line-height: 0.8rem;
            }
            color: white;
            text-decoration: none;
        }

        .register {
            margin-top: -3px;
            @include media-breakpoint-down(sm) {
                margin-top: 1px;
            }
        }

        .lighter-grey {
            color: #cccccc;
            font-size: 0.85rem;
            line-height: 0.85rem;
        }

        .live-chat-mobile {
            justify-content: flex-end;
            @include media-breakpoint-down(sm) {
                justify-content: flex-start;
            }
        }

        .navmenu_mobile_footer_tel_icon {
            font-size: 1.3rem;
            color: white;
            width: 11%;
            @include media-breakpoint-down(lg) {
                vertical-align: baseline;
            }
            @include media-breakpoint-down(md) {
                width: 9%;
            }
            @include media-breakpoint-down(sm) {
                width: 8%;
                vertical-align: unset;
            }
        }

        .navmenu_mobile_footer_telnumber {
            font-size: 24px;
            color: white;
            @include media-breakpoint-down(md) {
                font-size: 20px;
            }
        }

        .navmenu_mobile_footer_livechat_icon {
            font-size: 1.3rem;
            color: white;
        }

        .navmenu_mobile_footer_livechat {
            font-size: 1.3rem;
            color: white;
        }

        .live-chat-footer-mobile {
            width: 12%;
            @include media-breakpoint-down(lg) {
                vertical-align: text-bottom;
            }
            @include media-breakpoint-down(sm) {
                width: 8%;
                vertical-align: unset;
            }
        }
    }
}

.nav_mobile_footer_contact
{
    ul {
        li {
            padding: 7px 0 7px 0 !important;
            font-size: 1rem;
            color: #cccccc;
            a {
                padding: 5px 0 5px 0;
                color: #5e696e;
            }

            i { font-size: 1rem; width: 12px;}
        }
    }
}
.last-product-mobile-menu{
    background-repeat: no-repeat !important;
}


