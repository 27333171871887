/* CSS COMMON TO ALL MENU SIZES */

#navmenu_desktop {
    @include media-breakpoint-down(lg) {
        display: none;
    }
    float: left;
    font-size: 14px;
    width: 380px;

    > div > a {
        font-weight: 600;
        float: left;
        color: #223149;
        margin-right: 38px;
        padding-top: 2px;
        padding-bottom: 25px;
        font-size: 1rem;

        &:hover {
            color: $gtech-green;
            transition: all 0.5s;
            cursor: pointer;
        }

        img {
            width: auto;
            height: 11px;
            vertical-align: baseline;
        }
    }

    .dropdown-text:before {
        content: '\f0d7';
        margin-right: 5px;
        font-family: "Font Awesome 5 Free";
        color: $gtech-green;
    }

    .parent.dropdown {
        margin: 0;
    }

    .parent.dropdown.support, .parent.dropdown.login {
        float: right;

        img {
            margin-right: 2px;
        }
    }

    .parent.dropdown.support .navmenu_desktop_product_title li {
        a {
            line-height: 2;
            display: block;
            margin: 8px 0px 8px 0px;
            font-weight: 600;
            font-size: 20px;
            letter-spacing: -0.025rem;
            border-bottom: 2px solid #cfcfcf;
            width: 350px;
            @include media-breakpoint-down(lg) {
                font-size: 30px;
                font-weight: normal;
            }
            @include media-breakpoint-down(md) {
                font-size: 26px;
            }
        }
    }

    .parent.dropdown.support .navmenu_desktop_product_title li:last-child {
        a {
            border-bottom: 0px;
        }
    }
}

.dropdown-menu {
    border-radius: 0 !important;
}

#nav_wrapper {
    .control {
        padding: 0px;
    }

    .block-search {
        margin-top: 14px;
        @include media-breakpoint-down(lg) {
            margin-right: 20px;
        }
    }

    .showcart {
        margin-top: 3px;
    }
}

.dropdown-menu {
    border-radius: 0 !important;
}

.panel.wrapper {
    background-color: #f3f3f3 !important;
}

/* MAIN HEADER LINKS */

.navmenu_desktop_panel {
    width: 100vw;
    position: fixed;
    z-index: 1040;
    top: 92px;
    left: 0vw;
    border: 0px;
    box-sizing: border-box;
    margin: 16px 0px 0px 0px;
    border-top: 1px solid #B7B7B7;
    background-color: #EBEBEB !important; // Override Bootstrap default
}


.navmenu_desktop_content {
    .support-section-navigation {
        padding-top: 93px;
    }

    .support-section-navigation:nth-child(2) {
        border-left: 1px solid #CBCBCB;
        padding-left: 2rem;
        height: 100%;
        padding-top: 7rem;

    }

    .right-nav-title {
        font-size: 18px;
    }

    P {
        font-size: 1rem;
    }

    .row {
        height: 409px;

        a {
            float: left;
            clear: both;
            text-decoration: none;
            color: #223149;
            font-weight: 400;
            font-size: 15px;
            transition-property: all;
            transition-duration: 0.5s;
            transition-timing-function: ease;
            transition-delay: 0.01s;
            -webkit-transition: all 0.5s ease;
            @include media-breakpoint-down(lg) {
                font-size: 13px;
            }

            &:hover {
                color: #95d700;
                cursor: pointer;
            }
        }
    }
}

.header.panel > .header.links > li {
    margin: 0 !important;
}

.tp-widget-trustscore {
    font-size: 0.75rem;
    font-family: Gibson;
}

.navmenu_desktop_content_right_support {
    padding-left: 50px;
    margin-top: 80px;

    p {
        width: 29%;
        margin-top: 15px;
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 20px;
    }
}

.support-dropdown-img {
    background: url(/media/gtech/nav/promo_support.png) no-repeat;
    background-size: 35%;
    background-position: bottom right;
    margin: auto;
}


/* MEGA MENU */
.page-header {
    .navmenu-dropdown-img {
        background-image: url("/media/gtech/nav/nav-hero.jpg");
        background-repeat: no-repeat;
        background-size: 74%;
        background-position: 100% 75%;
    }

    #navmenu_desktop_support {
        background-image: none;
    }

    .mega-menu ul {
        margin: 0px 0px 20px 0px;
        padding: 0px;
    }

    .mega-menu > ul {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .mega-menu {
        li:nth-child(1) {
            margin-bottom: .1rem;
        }
    }

    .level0 {
        border-right: 1px solid #CBCBCB;
        padding-right: 3rem;
        padding-left: 1rem;
        @include media-breakpoint-down(lg) {
            padding: 0;
            border-right: 0;
        }

        .submenu {
            border: 0;
            line-height: 1.5;
        }
    }

    .level0:last-child {
        border-right: 0;
    }

    .level0:nth-child(1) {
        padding-left: 0;
    }


    /* Top Level Category */
    .mega-menu .category-item .level-top {
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        color: $gtech-green;
    }

    .level0.submenu {
        margin-top: 1rem;
    }

    .level1.submenu {
        margin-bottom: 25px !important;
    }

    /* Sub Category */
    .mega-menu .submenu .category-item > a {
        font-size: 16px;
        font-weight: 500;
        color: black;
        text-transform: uppercase;
        line-height: 1;
    }

    /* Products */
    .mega-menu .category-item.product {
        margin-bottom: 0.3rem;
    }

    .mega-menu .category-item.product li:nth-last-child(2) {
        margin-bottom: 1rem;
    }

    .mega-menu .submenu .category-item.product > a {
        font-weight: 400;
        text-transform: none;
        color: #5E696E;

        &:visited {
            color: #5E696E;
        }

        &:active {
            color: #5E696E;
        }
    }
}


/* Nav Desktop Footer */

.navmenu_desktop_footer_full_width {
    background-color: #636469;
}

.navmenu_desktop_footer {
    padding: 0 25px 0 25px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 0;

    a {
        @include media-breakpoint-down(md) {
            font-size: 11px;
        }
        font-weight: 600;
        color: white;
        text-decoration: none;
    }

    .lighter-grey {
        color: #cccccc;
        font-size: 14px;
    }

    .desktop-footer-nav-phone {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 18%;
    }

    .offline-container {
        width: 40%;
    }
}

.navmenu_desktop_footer_right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 545px;

    .live-chat-icon {
        width: 45px !important;
        height: auto !important;
    }

    .live-chat-offline-nav {
        font-size: 28px;
        font-weight: normal;
    }

    .live-chat-footer {
        width: 9%;
    }

    .live-chat-footer-online {
        width: 30px;
    }
}

.navmenu_desktop_footer_spares_icon {
    padding: 0;
    margin: 0 15px 0 0;
    @include media-breakpoint-down(md) {
        margin: 0 5px 0 0;
    }
}

.navmenu_desktop_footer_register_icon {
    padding: 0;
    margin: 0 15px 0 15px;
    @include media-breakpoint-down(md) {
        margin: 0 5px 0 5px;
    }
}

.navmenu-flex-align-center {
    display: flex;
    align-items: center;
}

.navmenu_desktop_footer_tel_icon {
    width: 25px;
    height: 25px;
    padding: 0;
    @include media-breakpoint-down(md) {
        margin: 0 5px 0 0;
    }
}

.navmenu_desktop_footer_email_icon {
    height: 20px !important;
    padding: 0;
    @include media-breakpoint-down(md) {
        margin: 0 5px 0 0;
    }
}

.navmenu_desktop_footer_livechat {
    font-weight: normal;
    font-size: 1.7rem;
    color: #ffffff;
    padding: 0;
    margin: 0;
    @include media-breakpoint-only(lg) {
        font-size: 1.3rem;
    }
}

.navmenu_desktop_footer_livechat_icon {
    padding: 0;
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.massage-bed-text {
    margin-top: 5.0625rem !important;
}

.navmenu_desktop_footer_lovetohelp {
    font-size: 1.1rem;
    @include media-breakpoint-down(md) {
        font-size: 13px;
    }
    color: #d8d8d8;
    padding-right: 25px;
    @include media-breakpoint-down(md) {
        padding-right: 5px;
    }
    font-weight: 500;
}

.navmenu_desktop_footer_text {
    font-size: 1.75rem;
    @include media-breakpoint-only(lg) {
        font-size: 1.3rem;
    }
    @include media-breakpoint-down(md) {
        font-size: 17px;
    }
    font-weight: normal;
    color: #ffffff;
    padding: 0;
    margin: 0;
}

.track-my-order-icon {
    fill: white;
}


/*BAU messaging*/

.black-friday-soon-banner {
    width: 100%;
    height: 4rem;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-down(sm) {
        height: 4rem;
    }
    .black-friday-container {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
        .black-friday-signup {
            color: $gtech-green;
            font-size: 1rem;
            font-weight: 500;
            text-decoration: underline;
            line-height: 1;
            text-align: right;
            a {
                color: $gtech-green !important;
                &:visited {
                    color: $gtech-green !important;
                }
            }
        }
        .hero-text {
            font-size: 1.25rem;
            letter-spacing: -0.035rem;
            font-weight: 600;
            color: #fff;
            margin-bottom: 0;
            line-height: 1;
            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }
        }
    }
}

.black-friday-banner {
    width: 100%;
    height: 5rem;
    background: black;
    display: flex;
    border-bottom: 1px solid #494949;
    flex-dalign-items-centerirection: column;
    justify-content: center;
    @include media-breakpoint-down(sm) {
        height: 6rem;
    }
    .black-friday-logo {
        height: 15px;
        margin-right: 5px;
    }
    .black-friday-container {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
        .black-friday-dates {
            color: #898989;
            font-size: .9rem;
            font-weight: 600;
            @include media-breakpoint-down(sm) {
                font-size: .8rem;
            }
            a {
                color: $gtech-green !important;
                &:visited {
                    color: $gtech-green !important;
                }
            }
        }
        .hero-text {
            font-size: 1rem;
            letter-spacing: -0.035rem;
            font-weight: 600;
            color: #fff;
            margin-bottom: 0;
            line-height: 1.2;
            @include media-breakpoint-down(sm) {
                font-size: .8rem;
            }
            a {
                text-decoration: underline;
                margin-left: 5px;
                color: $gtech-green !important;
                &:visited {
                    color: $gtech-green !important;
                }
            }
        }
    }
}

.bau-section {
    width: 100%;
    height: 3.5rem;
    background: $gtech-grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-down(sm) {
        height: 4rem;
    }
}

.bau-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
}

.open-for-business {
    font-size: 1.25rem;
    letter-spacing: -0.035rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
    line-height: 1;
    @include media-breakpoint-down(md) {
        font-size: 1rem;
    }

    span {
        font-size: 1.125rem;
        color: $gtech-green;
        /*text-decoration: underline;*/
        margin-left: 0.3rem;
        @include media-breakpoint-down(md) {
            font-size: 1rem;
        }
    }
}

.term-messaging-container {
    align-items: center;

    p {
        font-size: 1.125rem;
        letter-spacing: -0.035rem;
        font-weight: 600;
        color: #fff;
        margin-bottom: 0;
        text-transform: uppercase;
        @include media-breakpoint-down(md) {
            font-size: 1rem;
        }
        @include media-breakpoint-down(sm) {
            font-size: 0.9375rem;
            line-height: 1;
            text-align: right;
            font-weight: 500;
        }

        span {
            color: #FFFFFF;
            text-decoration: underline;
            font-weight: 500;
            margin-left: 0.5rem;
            font-size: 1rem;
            @include media-breakpoint-down(sm) {
                font-size: 0.9375rem;
            }
        }
    }
}

.truck-message {
    width: 1.4375rem;
    margin-right: .3rem;
    @include media-breakpoint-down(sm) {
        width: 1rem;
    }
}

.line-separator-header {
    border-top: 1px solid #B2B2B2;
    width: 100%;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
}

.navmenu_desktop_green_button {
    padding: 6px 15px 6px 15px;
    width: auto;
    background: #95d700;
    border-radius: 5px;
    color: #fff !important;
    font-size: 14px;
    text-align: center;
    box-shadow: none;
    border: 2px solid #95d700;
}

.navmenu_desktop_green_button:hover {
    box-shadow: none;
    background: 0 0;
    color: #95d700 !important;
    border: 2px solid #95d700;
}

//Hide Search Bar

.block-search {
    display: none;
}



