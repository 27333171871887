#logobar_fullwidth {
    background-color: #223149;
    width: 100%;
    height: 40px;
    clear: both;
    @include media-breakpoint-down(xs) { height:30px;}
}

#logobar {
    height: 40px;
    margin: 0;
    padding: 0;
    .action {
        margin-bottom: 0 !important;
    }

}

.inner-topbar-container {
    float: left;
    width: 100%;
    padding: 0px 0 0 208px;
    margin: 0;
    text-align: left;
    height: 100%;
    @media only screen and (max-width: 1280px){
        padding-left: 0px;
    }
    @media only screen and (max-width: 450px) {
        height: auto;
    }
    a {
        display: flex;
        flex-direction: row;
    }

    .trustpilot-widget {
        padding-top: .8rem;
        width: 29%;
        margin-top: -3px;
        @media only screen and (max-width: 980px) {
            width: 41%;
        }
        @media only screen and (max-width: 705px) {
            display: none;
        }
        .tp-widget-trustscore{
            display: none !important;
        }

    }
}


.text-top-term {
    font-size: .75rem;
    color: $gtech-green;
    font-weight: 600;
    @include media-breakpoint-down(md) {
        font-size: 0.6rem;
    }

}


.text-top-quote {
    text-decoration: underline;
    font-size: 0.75rem;
    @include media-breakpoint-down(md){
        font-size: 0.6rem;
    }
}

#nav_full_width {
    width: 100%;
    margin: 0;
    background-color: #f3f3f3;
    border-bottom: 1px solid #b3b5b4;
    height: 70px;
}

#gtech-logo {
    width: 154px;
    height: 156px;
    display: flex;
    @include media-breakpoint-up(xl) {display: flex !important;} // Alway show logo on desktop template if mobile nav has been left open and page rescaled
    @include media-breakpoint-down(sm) {display: none !important;} // Alway show logo on desktop template if mobile nav has been left open and page rescaled
    align-items: center;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 901;
    float: left;
    background-color: $gtech-green;
    @include media-breakpoint-down(lg) { display: none;}
    a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img {
        width: 100%;
        height: auto;
        padding: 0;
    }



}

#nav_wrapper {
    display: block;
    .action.showcart {
        background-color: unset;
        color: black;
        padding-top: 7px;
        height: 65px;
        @media only screen and (max-width: 480px) {
            padding-top: 0;
        }

        .counter.qty {
            background-color: #95d700;
            border-radius: 15px;
            font-size: .8em;
            font-weight: 600;
            position: absolute;
            margin-top: -5px;
            margin-left: -15px;
            text-align: center;
            width: 28px;
            height: 28px;
            line-height: 2.2;
        }
        &:before {
            font-size:50px;
        }
        &:hover { border: 0px;}
    }
}

#nav_desktop {
    float: left;
    display: -webkit-flex;
    /* Safari */
    -webkit-align-items: center;
    /* Safari 7.0+ */
    display: flex;
    align-items: flex-start;
    @include media-breakpoint-up(lg) {margin-left: 210px;}
    padding-top: 17px;
    height: 40px;
    div {
        a {
            img {
                width: auto;
                height: 11px;
                vertical-align: baseline;
            }
        }
    }
}

#nav_mobile {
    float: right;
    @include media-breakpoint-up(xl) {display: none;}
    @include media-breakpoint-up(md) {border-left: 1px solid #b7b7b7;}
    height: 70px;
    display: flex;
    align-items: center;
    margin-top: 0;
    padding-left: 30px;
    width:120px;
    @include media-breakpoint-down(sm) { width:initial; padding-left: 0;}
    @include media-breakpoint-down(xs){height:40px;}
}


#basket {
    @include media-breakpoint-up(xl) {border-right: 1px solid #b7b7b7;}
    @include media-breakpoint-up(lg) {border-left: 1px solid #b7b7b7;}
    @include media-breakpoint-down(lg){margin-top: 0;}
    @include media-breakpoint-down(xs){height:40px; width: 95px;}
    margin: 0;
    padding: 0 28px 0 28px;
    float: right;
    width:100px;
    height: 70px;
    margin-top: -5px;
    display: -webkit-flex;
    /* Safari */
    -webkit-align-items: center;
    /* Safari 7.0+ */
    display: flex;
    align-items: center;
    img {
        width: auto;
        height: 28px;
        margin-top: -2px;
    }
    .basket-count {
        position: absolute;
        margin-top: -42px;
        margin-left: 25px;
        text-align: center;
        width: 28px;
        height: 28px;
        line-height: 2.2;
        color: $white;
        overflow: hidden;
        background-color: $gtech-green;
        border-radius: 15px;
        font-size: 0.8em;
        font-weight: 600;

    }
}

#searchicon {
    width: 60px;
    @include media-breakpoint-up(md) {border-left: 1px solid grey;}
    margin: 0 0 0 10px;
    padding: 0 0 0 15px;
    float: right;
    height: 70px;
    display: -webkit-flex;
    /* Safari */
    -webkit-align-items: center;
    /* Safari 7.0+ */
    display: flex;
    align-items: center;
    img {
        width: auto;
        height: 28px;
        margin: 0;
    }
}

.breadcrumbs {
    padding: 1rem 20px 0 20px;
    margin-right: auto;
    @include media-breakpoint-down(sm) {
        padding: .6rem 0px 0px 0px;
        margin-bottom: 5px;
    }
}

.breadcrumbs ul {
    font-size: 0.875rem !important;
    li {
        line-height: 1.75rem !important;
        font-size: 0.875rem !important;
        a { font-size: 0.875rem;}
        &:after {
            color: $gtech-green;
            line-height: 1.75rem !important;
        }
        &:last-child {
            color: #5E696E;

        }
    }
}

.page-header {
    margin-bottom: 0px;
    .panel.wrapper {
        background-color: #f3f3f3;
        border-bottom: 1px solid #b3b5b4;
        height: 68px;
        @include media-breakpoint-down(md) {
            background-color: white;
            border-bottom: 0;
        }
        @include media-breakpoint-down(sm) {
            height: 70px;
        }
    }
}

.page-wrapper {
    .page-header {
        .header.panel {
            padding-top: 18px;
            padding-bottom: 0px;
            background-color: #f3f3f3;
        }

        .authorization-link {
            font-weight: 600;
            float: left;
            color: #223149;
            margin-right: 15px;
            padding-top: 2px;
            padding-bottom: 25px;
            font-size: 1rem !important;
            @include media-breakpoint-down(lg) {
                display: none !important;
            }

            a {
                img {
                    height: 11px;
                    margin-bottom: 2px;
                }
                &:hover {
                    color: $gtech-green;
                }

                color: #223149;
                text-transform: uppercase;
            }
        }
    }
}

.minicart-wrapper {
    border-left: 1px solid #b7b7b7;
    border-right: 1px solid #b7b7b7;
    @media only screen and (max-width: 1280px) {
        border: 0;
        margin-right: 1rem;
    }
    @include media-breakpoint-down(sm) {
        height: 40px;
    }
    @media only screen and (max-width: 600px) {
        margin-top: 0;
    }
    margin-left: 2rem !important;
}

.minicart-wrapper .action.showcart:before {
    vertical-align: -webkit-baseline-middle;
    content: url("/media/gtech/header/basket-icon.svg");
    color: $gtech-grey-2;
    font-size: 18px !important;
    margin-top: 12px;
    margin-right: 7px;
}

ul.header.links {
    height: 50px ;
}

.parent.dropdown.support {
    height: 50px;
    padding-top: 2px;
}

#nav_wrapper .action.showcart {
    margin-bottom: 0 !important;
}





