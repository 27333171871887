/* COMMON CSS USED ON ALL PAGES OF THE GTECH SITE */

/*Removing white space on the right hand side*/
html, body {
    width: 100%;
    font-size: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.9;
    /* DO NOT PUT OVERFLOW-X: HIDDEN HERE!!!! */
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
}
.breadcrumbs {
    @include media-breakpoint-down(md){
        padding-left: 115px !important;
        margin-top: 1rem;
    }
    @include media-breakpoint-down(sm) {
        padding-left: 20px !important;
        margin-top: .5rem;
    }
}

p {
    font-size: 1rem;
}

.row {
    margin: 0;
}

.download-link {
    text-decoration: underline;
}

h1 {
    font-size: 3.75rem;
    @include media-breakpoint-down(md) {
        font-size: 2.25rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
    }
}

/*Removing the hint text all on product pages*/

.mz-hint {
    display: none !important;
}

.generic_green_button {
    padding: 6px 15px 6px 15px;
    width: auto;
    background: #95d700;
    border-radius: 5px;
    color: $white !important;
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0.01s;
    -webkit-transition: all 0.5s ease;
    border: 2px solid #95d700;

    &:hover {
        box-shadow: none;
        background: transparent;
        color: #95d700 !important;
        border: 2px solid #95d700;
    }
}

.generic_darkgrey_button {
    padding: 5px 18px 5px 18px;
    width: auto;
    background: $gray-700;
    border-radius: 5px;
    color: $white;
    font-size: 18px;
    text-align: center;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0.01s;
    -webkit-transition: all 0.5s ease;
    border: 2px solid $gray-700;

    &:hover {
        box-shadow: none;
        background: transparent;
        color: $gray-700;
        border: 2px solid $gray-700;
    }
}


.add_to_basket_button {
    box-shadow: none;
    background: #8ABE25;
    border: 2px solid #8ABE25;
    color: $white;
    font-weight: bold;
    font-size: 17px;
    padding: 10px 45px 10px;
    -webkit-transition: all 0.5s ease;
    text-decoration: none;
    -webkit-border-radius: 4px;

    &:hover {
        box-shadow: none;
        background: transparent;
        color: #8ABE25;
        border: 2px solid #8ABE25;
        text-decoration: none;
    }
}

a {
    color: $gray-900;
}

a:visited, .alink:visited {
    color: inherit;
}

.action, .action.primary, .action:hover {
    background: $gtech-green;
    color: white;
    padding: 0.7rem 1rem;
    border-radius: 5px;
    font-weight: 400;
    border: 0px;
    transition: 0.2s;
    width: auto !important;
    margin-bottom: 1rem !important;
}

.action:hover, .action.primary:hover, .action.primary:active, .action.primary:focus {
    background: #7ab100;
    border: 0px;
}

ul, ol {
    list-style: none;
}

textarea, select, input, input:focus, button {
    outline: none;
}

.flcb {
    float: left;
    clear: both;
}


.green-star {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-image: url('/media/gtech/product/common-images/icon_green_star.svg');
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding: 0px;
    margin: 0px;
}

.green-half-star {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-image: url('/media/gtech/product/common-images/icon_green_half_star.svg');
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding: 0px;
    margin: 0px;
}

/***** Second Navbar *****/

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
}


// Start of M2, Fix conflict between magento native classes and bootstrap "col"
table.table tr {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
}

.btn-secondary {
    color: white !important;
}

.btn-gtech-grey {
    background-color: $gtech-grey;
    color: $white !important;

    &:visited {
        color: inherit;
    }

    &.action {
        font-size: 1rem !important;
    }

    &:hover {
        -webkit-box-shadow: 0px 0px 20px -6px $gtech-green;
        -moz-box-shadow: 0px 0px 20px -6px $gtech-green;
        box-shadow: 0px 0px 20px -6px $gtech-green;
        background-color: transparent;
        color: $gtech-grey !important;
        border: 1px solid $gtech-grey;
    }
}

.btn-primary {
    color: white !important;
}

.btn-large {
    padding: 12px 35px 12px 35px;
    transition: 0.2s;
    border-radius: 5px;
    font-size: 1.3rem;
}

label {
    font-weight: 600;
}
.page-title-wrapper {
    margin-top: 2rem;

}
strong {
    font-weight: 500;
}

.tooltip-inner {
    background-color: #22262A;
    font-size: 14px;
    font-weight: 100;
}
.list-group-item:visited {
    color: inherit;
}

.livechat-modal, #kpd_koopidtag {
    cursor: pointer;
}


/***** One Trust Preference Centre Styling Overrides ****/
#onetrust-pc-sdk #ot-pc-title, #onetrust-pc-sdk #ot-category-title, #onetrust-pc-sdk .ot-cat-header, #onetrust-pc-sdk #ot-lst-title, #onetrust-pc-sdk .ot-ven-hdr .ot-ven-name, #onetrust-pc-sdk .ot-always-active
{
    font-weight: normal !important;
}

#ot-sdk-btn-floating.ot-floating-button button {
    padding: 0px !important;
}

// Styles added by HM
.video-container {
    position: static;
    width: 100%;
    max-width: 3000px;
    margin: auto;
    height: 125vw;
    background-color: black;
    overflow: hidden;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    video.lg, video.md {
        display: none;
    }
    @include md-and-up {
        height: 360px;
        video.sm {
            display: none;
        }
        video.md {
            display: block;
        }
    }
    @include lg-and-up {
        height: 400px;
    }
    @include xl-and-up {
        height: 600px;
    }
    @include xxl-and-up {
        height: 900px;
        video.md {
            display: none;
        }
        video.lg {
            display: block;
        }
    }
}


/*
Hide the GTM tracking pixel so that there isn't white space at the bottom of the page !!!
 */
img[src*="secure.adnxs.com"] {
    display: none;
}
