/***** Footer Section *****/

footer {
    .join_gtech_community
    {
        background-color: $gtech-lighter-grey;
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
        @include media-breakpoint-down(md) {
            padding-top: 2rem;
            padding-bottom: 1rem;
        }
        .newsletter-text {
            color: $gtech-grey;
            font-size: 1.25rem;
            line-height: 1;
            max-width: 55%;
            font-weight: 500;
            @include media-breakpoint-down(md) {font-size:0.9375rem; line-height: 16px; letter-spacing: -.3px; max-width: 47%;}
            @include media-breakpoint-down(sm) {font-size:0.9375rem;}
        }
        .exclusive {
            color: $switch-dark;
        }
        .newsletter-icon{
            width:5rem;
            height:3.125rem;
            @include media-breakpoint-down(md) {height: 40px; width: auto;}
        }
        .sign_up_box {
            padding: 0.3rem 2.5rem;
            @include media-breakpoint-down(sm) {padding-left: 1rem; padding-right: 1rem;}
            background-color: white;
            border-radius: 1.3rem;
            -moz-border-radius: 1.3rem;
            -webkit-border-radius: 1.3rem;
            width:75%;
            @include media-breakpoint-down(sm) {width:100%;}
            @include media-breakpoint-up(lg) {width:50%;}
            .newsletter_signup_btn{
                background: none;
                margin-left: -1.25rem;
            }
            .enter_email{
                color:#a4b0b8;
                font-size: 1.2rem;
            }
        }

        .sign-up-btn
        {
            margin: 0 0 0 35px;
            width:11rem;
            height:2.875rem;
            background-color: $gtech-grey;
            border-color: $gtech-grey;
            padding-top: 0.4rem;
            font-size: 1.25rem;
            color:#fff;
            @include media-breakpoint-up(lg) { margin: 0 0 0 35px;}
            @include media-breakpoint-down(sm) { width:100%; margin-right: 5%; margin-left: 5%;}
            &:hover{
                background-color: transparent;
                border-color: $gtech-grey;
                color:$gtech-grey;
            }

        }
    }
    .footer-help {
        margin-bottom: -15px;
    }
    .offline-container{
        align-items: baseline;
        @include media-breakpoint-down(md) {  align-items: center;}
    }
    .help-container {
        display: flex;
        align-items: center;
        background: $gtech-grey;
        height:7.5rem;
        white-space:nowrap;
        .help-elements {
            display:flex;
            justify-content: space-evenly;
            align-items: center;
            font-weight: 500;
            letter-spacing: -1px;
        }
        span {
            font-size: 2.5rem;
            @include media-breakpoint-down(lg) {font-size: 2.25rem;}
            @include media-breakpoint-down(sm) {font-size: 1.625rem;}
        }
        div.email-footer {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            div.email-icon-text {
                display: flex;
                align-items: center;
                gap: 1rem;
            }
            @include sm-and-up() {
                flex-direction: row;
            }
        }
        .phone-footer{
            height: 25px;
            width: 25px;
            @include media-breakpoint-down(sm) {
                height: 22px;
                width: 22px;
                padding-top: 2px;
            }
        }

        .here-to-help {
            margin-right: 80px;
        }

        .live-chat-footer{
            padding-bottom: 15px;
            width: 32px;
            @include media-breakpoint-down(sm) {
                width: 24px;
                padding-bottom: 8px;
            }
        }
        .offline-chat{
            align-items: baseline;
            @include media-breakpoint-down(sm) {align-items: center;}
        }
    }
    .main-footer-section {
        background: url(/media/gtech/footer/footer-multi-background.jpg) no-repeat;
        background-color: $gtech-grey;
        background-size: cover;
        background-position: center;
        position: relative;
        height: auto;
        @include media-breakpoint-down(lg) {background-position: 70%; height:28.75rem;}
        @include media-breakpoint-down(md) {height: 30rem;}
        .container {
            @include media-breakpoint-up(xl) {
                max-width: 1500px;
            }
        }

    }

    a, a:hover {
        color: #fff;
    }


    .footer-social-block{
        margin-top: 3.75rem;
    }
    .rights-mobile{
        color:#b5c7d8;
        font-size: 0.75rem;
        width:90%;
    }
    .gtech-logo-footer{
        width:5.5rem;
    }

    .sign-up-mobile-container{
        background: #fff;
        width: 100%;
        border-radius: 21.5px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 5%;
        padding-right: 5%;
        .left-sign-up{
            font-size: 1rem;
            color:#a4b0b8;
        }
    }
}


.main-footer-section {
    .container {
        height: 100%;
    }
.main-nav-section .payment-methods-container {
    display: none !important;
}







    .footer-menu {
        text-align: left;
        display: flex;
        flex-direction: row;
    }

    .footer-right {
        li {
            font-size: .9rem;
        }
    }

    .footer-left {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    ul {
        padding: 0;
        @include media-breakpoint-down(lg) {
            margin-bottom: 0;
        }
    }

    ul:nth-child(1) {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }


    /*--Old footer menu styling - can potentially be removed - HA */
    .category-item .level-top {
        font-size: .9rem;
        font-weight: 600;
        margin-bottom: 10px;
        text-transform: uppercase;
        text-decoration: underline;
        @include media-breakpoint-down(lg) {
            font-size: 1.2rem;
            text-decoration: none;
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    li.level-top {
        font-size: 1.2rem;
        text-decoration: none;
        font-weight: 400;
        @include media-breakpoint-down(lg) {
            margin-bottom: .5rem;
        }
    }

    .submenu {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .level0 {
        margin-right: 1.5rem;
        @include media-breakpoint-down(sm) {
            margin-right: 0;
        }
    }

    /* Sub Category */

    .footer-title, .up-to-date-title {
        color: #b2b2b2;
        font-size: 1.5rem;
        letter-spacing: -.030rem;
        margin-bottom: 1.5rem;
        font-weight: 400;
        text-align: left;
        @include media-breakpoint-down(lg) {
            font-size: 1.5rem;
            border-bottom: 1px solid grey;
            width: 45%;
        }
        @include media-breakpoint-down(md) {
            border-bottom: 0;
            text-align: center;
            width: 100%;
            margin-bottom: 0rem;
        }
    }

    .submenu .category-item > a {
        font-size: .85rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    .submenu li {
        margin-bottom: -3px;
    }

    /* Products */

    .category-item.product li:nth-last-child(2) {
        margin-bottom: 0px
    }

    .last {
        margin-bottom: .3rem !important;
        @include media-breakpoint-down(lg) {
            margin-bottom: 0rem !important;
        }
    }

    .submenu .category-item.product > a {
        font-weight: 300;
        text-transform: none;
    }


    //Company Links List
    .footer-link-list {
        text-align: left;
        @include media-breakpoint-down(lg) {
            font-size: 1.2rem;
            text-decoration: none;
            font-weight: 400;
            text-transform: uppercase;
        }
    }





    //Can We Help Icons
    .contact-icon {
        width: .875rem;
        margin-right: .5rem;
    }

    .support-icon, .faq-icon, .spares-icon, .delivery-icon {
        width: 1rem;
        margin-right: .5rem;
    }

    //Social Media Icons

    .social-icons {
        @include media-breakpoint-down(md) {
            margin: auto;
        }

        .youtube-icon {
            width: 1.8125rem;
        }

        .insta-icon {
            width: 2.1875rem;

        }

        .facebook-icon {
            width: 1.1875rem;
        }
    }

    .payment-methods-container {
        text-align: left;
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-down(md) {
            margin-top: 3rem !important;
            display: none !important;
        }

        .pay-securely-footer {
            color: #b2b2b2;
            font-size: 1.375rem;
            text-transform: uppercase;
            text-align: left;
            @include media-breakpoint-down(lg) {
                font-size: 1rem;
            }
        }

        .padlock-icon {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    //Mobile Only Elements
    .menu-item {
        margin-bottom: 1.5rem;
        @include media-breakpoint-down(md) {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
        }
    }

    .social-icon-row {
        width: 80%;
        @include media-breakpoint-down(lg) {
            width: 60%;
        }
        @include media-breakpoint-down(md) {
            width: 35%;
        }
        @include media-breakpoint-down(sm) {
            width: 50%;
        }
    }


    //List Sections for Mobile Footer

    .mobile-product-footer-nav {
        display: flex;
        flex-direction: row;

        .footer-nav-content {
            position: absolute;
            left: 300px;
            top: 0px;
            @include media-breakpoint-down(md) {
                position: relative;
                left: 0;
                top: 0;
                text-align: center;
            }
            @include media-breakpoint-down(sm) {
                top: 0px;
            }
        }
    }

    .gtech-company-list {
        @include media-breakpoint-down(lg) {
            position: absolute;
            top: 0px;
            left: 300px;
        }
        @include media-breakpoint-down(md) {
            position: relative;
            left: 0;
            top: -35px;
            text-align: center;
        }
        @include media-breakpoint-down(sm) {
            top: -32px;
        }
    }

    .my-gtech-list {
        @include media-breakpoint-down(lg) {
            position: absolute;
            top: 0px;
            left: 300px;
        }
        @include media-breakpoint-down(md) {
            position: relative;
            left: 0;
            top: -15px;
            text-align: center;
        }
        @include media-breakpoint-down(sm) {
            top: -15px;
        }
    }

    .can-we-help-list {
        @include media-breakpoint-down(lg) {
            position: absolute;
            top: -160px;
            left: 300px;
        }
        @include media-breakpoint-down(md) {
            position: relative;
            left: 0;
            top: -50px;
            text-align: center;
        }
        @include media-breakpoint-down(sm) {
            top: -48px;
        }
    }

    .up-to-date-title {
        width: 100%;
        border-bottom: 0;
    }

    .keep-up-to-date {
        @include media-breakpoint-down(md) {
            margin-top: 2rem;
            padding-top: 1.5rem;
            border-top: 1px solid grey;
            border-bottom: 1px solid grey;
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }

    //JS Elements for Mobile Footer

    .footer-menu-show {
        @include media-breakpoint-down(lg) {
            display: block;
        }
    }

    .footer-menu-hide {
        display: block;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .active-title {
        @include media-breakpoint-down(lg) {
            color: white;
            border-bottom: 1px solid white;
        }
    }

    .show-parent-nav {
        h2, p {
            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }

    .hide-parent-nav {
        h2, p {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    .footer-close-button {
        display: none;
        @include media-breakpoint-down(md) {
            position: absolute;
            right: 0px;
            top: -15px;
            font-size: 30px;
            cursor: pointer;
            z-index: 100;
        }
    }

    .show-close-button {
        @include media-breakpoint-down(md) {
            display: block;
        }
    }
}


#bottommenu-navmenu {
    text-align: left;
}
/*--category styling--*/
.bottommenu-category h2 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-transform: uppercase;
    text-align:left;
}
.bottommenu-category h2 a {
    text-decoration: none;
    border-bottom: 2px solid transparent;
    padding-bottom: 0.2rem;
    transition: color 0.3s ease, border-color 0.3s ease;
    text-align:left;
    color: #FFFFFF;
    line-height: 1.5;
}
.bottommenu-category h2 a:hover {
    color: #95d700 !important;
    border-color: #95d700;
}
/*--end--*/

/*--subcategory styling--*/
.bottommenu-subcategory-item {
    text-align:left;
}
.bottommenu-subcategory .subcategory-link {
    font-size: 1.05rem;
    font-weight: 300;
    text-decoration: none;
    transition: color 0.3s ease, border-color 0.3s ease;
    text-align:left;
    color: #fff;
    display: block;
}
.subcategory-link:hover {
    color: #95d700 !important;
}
/*--end--*/

/* Bottom menu product styling */
.bottommenu-product {
    text-align: left;
}
.bottommenu-products-link {
    display: block;
    font-size: 0.95rem;
    font-weight: 300;
    color: #FFFFFF;
    transition: color 0.3s ease;
    text-align: left;
    line-height:1.2;
}
.bottommenu-products-link:hover {
    color: #95d700;
}
/*--end--*/

/*--Bottom footer--*/
.bottom-footer {
    background-color: #4d555b;
}
/*--end--*/
