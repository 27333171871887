.clear {
  clear: both;
}

/*Top Bar Info*/

.info-sup{
    font-size: 12px;
    top: -0.3rem;
    sup{
        font-size: 6px;
        top: -0.3rem;
    }
}

.inner-topbar-container {
    float: left;
    width: 100%;
    padding: 5px 0 0 190px;
    margin: 0;
    text-align: left;
}

.infobar-top {
  background-color: $new-gtech-blue;
  white-space: nowrap;
  height: 45px;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(lg) {display: none;}
}

.inner-topbar-container {
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include media-breakpoint-down(xs) {justify-content: center;}
}

.text-top {
  font-size: 0.9375rem;
  color: #96d429;
  line-height: 34px;
  font-weight: 600;
}

.text-top-number {
  font-size: 1rem;
  color: #5e696e;
  margin-left: 0.5625rem;
  margin-right: 6%;
  line-height: 34px;
  font-weight: 600;
  letter-spacing: -0.025rem;
}

.text-top-term, .text-top-term-mobile {
  font-size: 0.6875rem;
  color: #96d429;
  margin-left: 4px;
  margin-right: 3px;
  line-height: 34px;
  font-weight: 600;
}

#trust-pilot-text {
  color: #96d429;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: -1px;
}

.text-top-quote {
  font-size: 0.6875rem;
  color: #5e696e;
  line-height: 34px;
  font-weight: 600;
}

.warranty-text {
  text-decoration: underline;
}

.middle-set {
  display: flex;
  align-items: center;
    @media only screen and (max-width: 450px) {
        margin-right: .1rem !important;
    }
}

.guarantee-set {
  margin-left: 1.5625rem;
  margin-right: 1.5625rem;
}

.handset-img {
  width: 0.8125rem;
}

.feature-set {
  float: left;
    @include media-breakpoint-down(sm) {margin:auto;}
}

.phone-info{
  @include media-breakpoint-down(lg) {display: none;}
}

.images {
  margin-left: 0.5625rem;
}

.images-info-block {
  margin-left: 2.3%;
    @media only screen and (max-width: 450px) {
        display: none;
    }
}

.images-trustpilot-block1 {
  width: 5.3125rem;
  margin: 0 5px 0 5px;
}

.images-trustpilot-block2 {
  width: 4.625rem;
  vertical-align: sub;
}

.trolley-img {
  width: 1.5rem;
}

.arrows-img {
  width: 0.875rem;
}

.shield-img {
  width: 0.8125rem;
  vertical-align: baseline;
}

.middle-icon {
  margin-right: 0;
}

